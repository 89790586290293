import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 9,
    label: "Dashboard",
    icon: "bx-home-circle",
    link: "/dashboard",
  },
  // {
  //   id: 7,
  //   isLayout: true,
  // },



  {
    id: 36,
    label: "Clients",
    icon: "bx-group",
    link: "/clients/list",
  },

  {
    id: 36,
    label: "Demo Accounts",
    icon: "bx-group",
    link: "/clients/demo-accounts-list",
  },

  {
    id: 36,
    label: "User Access",
    icon: "bx-user",
    link: "/clients/user-access",
  },


  // {
  //   id: 36,
  //   label: "MENUITEMS.INVOICES.TEXT",
  //   icon: "bx-receipt",
  //   subItems: [
  //     {
  //       id: 37,
  //       label: "MENUITEMS.INVOICES.LIST.INVOICELIST",
  //       link: "/invoices/list",
  //       parentId: 36,
  //     },
  //     {
  //       id: 38,
  //       label: "MENUITEMS.INVOICES.LIST.INVOICEDETAIL",
  //       link: "/invoices/detail",
  //       parentId: 36,
  //     },
  //   ],
  // },

  // {
  //   id: 48,
  //   label: "MENUITEMS.CONTACTS.TEXT",
  //   icon: "bxs-user-detail",
  //   subItems: [
  //     {
  //       id: 49,
  //       label: "MENUITEMS.CONTACTS.LIST.USERGRID",
  //       link: "/contacts/grid",
  //       parentId: 48,
  //     },
  //     {
  //       id: 50,
  //       label: "MENUITEMS.CONTACTS.LIST.USERLIST",
  //       link: "/contacts/list",
  //       parentId: 48,
  //     },
  //     {
  //       id: 51,
  //       label: "MENUITEMS.CONTACTS.LIST.PROFILE",
  //       link: "/contacts/profile",
  //       parentId: 48,
  //     },
  //   ],
  // },
  // {
  //   id: 56,
  //   label: "MENUITEMS.PAGES.TEXT",
  //   isTitle: true,
  // },
  // {
  //   id: 57,
  //   label: "MENUITEMS.AUTHENTICATION.TEXT",
  //   icon: "bx-user-circle",
  //   badge: {
  //     variant: "success",
  //     text: "MENUITEMS.AUTHENTICATION.BADGE",
  //   },
  //   subItems: [
  //     {
  //       id: 58,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.LOGIN",
  //       link: "/account/login",
  //       parentId: 57,
  //     },
  //     {
  //       id: 59,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.LOGIN2",
  //       link: "/account/login-2",
  //       parentId: 57,
  //     },
  //     {
  //       id: 60,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.REGISTER",
  //       link: "/account/signup",
  //       parentId: 57,
  //     },
  //     {
  //       id: 61,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.REGISTER2",
  //       link: "/account/signup-2",
  //       parentId: 57,
  //     },
  //     {
  //       id: 62,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD",
  //       link: "/account/reset-password",
  //       parentId: 57,
  //     },
  //     {
  //       id: 63,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD2",
  //       link: "/account/recoverpwd-2",
  //       parentId: 57,
  //     },
  //     {
  //       id: 64,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN",
  //       link: "/pages/lock-screen-1",
  //       parentId: 57,
  //     },
  //     {
  //       id: 65,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN2",
  //       link: "/pages/lock-screen-2",
  //       parentId: 57,
  //     },
  //     {
  //       id: 66,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL",
  //       link: "/pages/confirm-mail",
  //       parentId: 57,
  //     },
  //     {
  //       id: 67,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL2",
  //       link: "/pages/confirm-mail-2",
  //       parentId: 57,
  //     },
  //     {
  //       id: 68,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION",
  //       link: "/pages/email-verification",
  //       parentId: 57,
  //     },
  //     {
  //       id: 69,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION2",
  //       link: "/pages/email-verification-2",
  //       parentId: 57,
  //     },
  //     {
  //       id: 70,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION",
  //       link: "/pages/two-step-verification",
  //       parentId: 57,
  //     },
  //     {
  //       id: 71,
  //       label: "MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION2",
  //       link: "/pages/two-step-verification-2",
  //       parentId: 57,
  //     },
  //   ],
  // },
  // {
  //   id: 72,
  //   label: "MENUITEMS.UTILITY.TEXT",
  //   icon: "bx-file",
  //   subItems: [
  //     {
  //       id: 73,
  //       label: "MENUITEMS.UTILITY.LIST.STARTER",
  //       link: "/pages/starter",
  //       parentId: 72,
  //     },
  //     {
  //       id: 74,
  //       label: "MENUITEMS.UTILITY.LIST.MAINTENANCE",
  //       link: "/pages/maintenance",
  //       parentId: 72,
  //     },
  //     {
  //       id: 74,
  //       label: "Coming Soon",
  //       link: "/pages/coming-soon",
  //       parentId: 72,
  //     },
  //     {
  //       id: 75,
  //       label: "MENUITEMS.UTILITY.LIST.TIMELINE",
  //       link: "/pages/timeline",
  //       parentId: 72,
  //     },
  //     {
  //       id: 76,
  //       label: "MENUITEMS.UTILITY.LIST.FAQS",
  //       link: "/pages/faqs",
  //       parentId: 72,
  //     },
  //     {
  //       id: 77,
  //       label: "MENUITEMS.UTILITY.LIST.PRICING",
  //       link: "/pages/pricing",
  //       parentId: 72,
  //     },
  //     {
  //       id: 78,
  //       label: "MENUITEMS.UTILITY.LIST.ERROR404",
  //       link: "/pages/404",
  //       parentId: 72,
  //     },
  //     {
  //       id: 79,
  //       label: "MENUITEMS.UTILITY.LIST.ERROR500",
  //       link: "/pages/500",
  //       parentId: 72,
  //     },
  //   ],
  // },

  // {
  //   id: 100,
  //   label: "MENUITEMS.FORMS.TEXT",
  //   icon: "bxs-eraser",
  //   badge: {
  //     variant: "danger",
  //     text: "MENUITEMS.FORMS.BADGE",
  //   },
  //   subItems: [
  //     {
  //       id: 101,
  //       label: "MENUITEMS.FORMS.LIST.ELEMENTS",
  //       link: "/form/elements",
  //       parentId: 100,
  //     },
  //     {
  //       id: 102,
  //       label: "MENUITEMS.FORMS.LIST.LAYOUTS",
  //       link: "/form/layouts",
  //       parentId: 100,
  //     },
  //     {
  //       id: 103,
  //       label: "MENUITEMS.FORMS.LIST.VALIDATION",
  //       link: "/form/validation",
  //       parentId: 100,
  //     },
  //     {
  //       id: 104,
  //       label: "MENUITEMS.FORMS.LIST.ADVANCED",
  //       link: "/form/advanced",
  //       parentId: 100,
  //     },
  //     {
  //       id: 105,
  //       label: "MENUITEMS.FORMS.LIST.EDITOR",
  //       link: "/form/editor",
  //       parentId: 100,
  //     },
  //     {
  //       id: 106,
  //       label: "MENUITEMS.FORMS.LIST.FILEUPLOAD",
  //       link: "/form/uploads",
  //       parentId: 100,
  //     },
  //     {
  //       id: 107,
  //       label: "MENUITEMS.FORMS.LIST.REPEATER",
  //       link: "/form/repeater",
  //       parentId: 100,
  //     },
  //     {
  //       id: 108,
  //       label: "MENUITEMS.FORMS.LIST.WIZARD",
  //       link: "/form/wizard",
  //       parentId: 100,
  //     },
  //     {
  //       id: 109,
  //       label: "MENUITEMS.FORMS.LIST.MASK",
  //       link: "/form/mask",
  //       parentId: 100,
  //     },
  //   ],
  // },
  // {
  //   id: 110,
  //   icon: "bx-list-ul",
  //   label: "MENUITEMS.TABLES.TEXT",
  //   subItems: [
  //     {
  //       id: 111,
  //       label: "MENUITEMS.TABLES.LIST.BASIC",
  //       link: "/tables/basic",
  //       parentId: 110,
  //     },
  //     {
  //       id: 112,
  //       label: "MENUITEMS.TABLES.LIST.ADVANCED",
  //       link: "/tables/advanced",
  //       parentId: 110,
  //     },
  //   ],
  // },
  // {
  //   id: 113,
  //   icon: "bxs-bar-chart-alt-2",
  //   label: "MENUITEMS.CHARTS.TEXT",
  //   subItems: [
  //     {
  //       id: 114,
  //       label: "MENUITEMS.CHARTS.LIST.APEX",
  //       link: "/charts/apex",
  //       parentId: 113,
  //     },
  //     {
  //       id: 115,
  //       label: "MENUITEMS.CHARTS.LIST.CHARTJS",
  //       link: "/charts/chartjs",
  //       parentId: 113,
  //     },
  //     {
  //       id: 116,
  //       label: "MENUITEMS.CHARTS.LIST.CHARTIST",
  //       link: "/charts/chartist",
  //       parentId: 113,
  //     },
  //     {
  //       id: 117,
  //       label: "MENUITEMS.CHARTS.LIST.ECHART",
  //       link: "/charts/echart",
  //       parentId: 113,
  //     },
  //   ],
  // },
  // {
  //   id: 118,
  //   label: "MENUITEMS.ICONS.TEXT",
  //   icon: "bx-aperture",
  //   subItems: [
  //     {
  //       id: 119,
  //       label: "MENUITEMS.ICONS.LIST.BOXICONS",
  //       link: "/icons/boxicons",
  //       parentId: 118,
  //     },
  //     {
  //       id: 120,
  //       label: "MENUITEMS.ICONS.LIST.MATERIALDESIGN",
  //       link: "/icons/materialdesign",
  //       parentId: 118,
  //     },
  //     {
  //       id: 121,
  //       label: "MENUITEMS.ICONS.LIST.DRIPICONS",
  //       link: "/icons/dripicons",
  //       parentId: 118,
  //     },
  //     {
  //       id: 122,
  //       label: "MENUITEMS.ICONS.LIST.FONTAWESOME",
  //       link: "/icons/fontawesome",
  //       parentId: 118,
  //     },
  //   ],
  // },

  // {
  //   id: 125,
  //   label: "MENUITEMS.MULTILEVEL.TEXT",
  //   icon: "bx-share-alt",
  //   subItems: [
  //     {
  //       id: 126,
  //       label: "MENUITEMS.MULTILEVEL.LIST.LEVEL1.1",
  //       link: "#",
  //       parentId: 125,
  //     },
  //     {
  //       id: 127,
  //       label: "MENUITEMS.MULTILEVEL.LIST.LEVEL1.2",
  //       parentId: 125,
  //       subItems: [
  //         {
  //           id: 128,
  //           label: "MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1",
  //           parentId: 127,
  //         },
  //         {
  //           id: 129,
  //           label: "MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2",
  //           parentId: 127,
  //         },
  //       ],
  //     },
  //   ],
  // },
];
